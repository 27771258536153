import React from 'react';
import Iframe from 'react-iframe';
import { widgetsMapping } from 'controlTower/ctConstants';
import { byId } from 'utils/utils';
import { useGState, ga } from 'state/store';

export default function Apps({ widgetType, branchId }) {
  const widget = byId(widgetsMapping, widgetType, 'name');
  const APPS_URL = useGState((s) => s[ga.PANEL_CONFIG].APPS_URL);

  const { date } = useGState((s) => ({
    date: s.date,
  }));
  // const gDispatch = useGDispatch();

  // useEffect(() => {
  //   if (!storeBranch || !branches || !branches.length) return;

  //   if (branchId && storeBranch.id === -1 && byId(branches, +branchId)) {
  //     gDispatch([ga.BRANCH, byId(branches, +branchId)]);
  //   } else if (Number(branchId) !== storeBranch.id) {
  //     navigate(`/ct/apps/${widgetType}/${storeBranch.id}`);
  //   }
  // }, [branchId, gDispatch, branches, storeBranch, widgetType]);

  // if (!storeBranch) return null;

  return (
    <Iframe
      position="relative"
      id="appsIframe"
      name="appsIframe"
      url={`${APPS_URL}${widget.appsUrl}?gDate=${date}&gBranchId=${branchId}&appbar=false`}
      width="100%"
      height="100%"
      className="appsIframe"
    />
  );
}
