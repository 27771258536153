import React from 'react';
import Layout from 'components/Layout';
import { Router } from '@reach/router';
import AppsView from 'controlTower/components/AppsView';

export default (props) => (
  <Layout {...props} useNodeSelector>
    <Router style={{ height: '100%', position: 'relative' }}>
      {/* <AppsView path="/ct/apps/:widgetType" style={{ height: '100%', position: 'relative' }} /> */}
      <AppsView
        path="/ct/apps/:widgetType/:branchId"
        style={{ height: '100%', position: 'relative' }}
      />
    </Router>
  </Layout>
);
